@media (min-width: 768px) {
  .welcomeContainer {
    margin-top: 0;

    h1 {
      padding: 0;
      margin: 0;
    }
  }
}

.sectionHead {
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 640px;
}

@media (max-width: 768px) {
  .sectionHead {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
