@import "@hagerty/design-system/src/_variables";

.root {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 64px;
}

.message {
  margin-right: 24px;
}
.link {
  color: $color-brand !important;
}
