@import "@hagerty/design-system/src/_variables";
@import '@hagerty/design-system/src/variables/_typeset.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: $helvetica-neue;
  font-weight: 500;
  height: 130px;
  width: 100%;
  padding: 16px;
  position: static;

  @media (min-width: 1440px) {
    height: 146px;
    width: 192px;
  }

  p {
    padding-bottom: 0px !important;
    text-align: left;
  }
}

.cta {
  color: $color-brand;
  line-height: 24px;
  font-size: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;

  svg {
    margin-left: 10px;
  }
}

.ctaWrapper {
  display:flex;
  column-gap: 6px;

  button {
    min-width: 32px
  }
}

// Small screen resolutions
@media (max-width: 434px) {
  .card {
    padding: 22px 16px 16px 16px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 725px) {
  .card {
    width: 145px;
  }

  .cta {
    margin-top: 0;
  }
}
