@import "@hagerty/design-system/src/_variables";

.column {
    padding-top: 64px;
    max-width: 640px;
    margin: 0 auto;
}
.title {
    padding-top: 16px;
    padding-bottom: 0px;
}
.subtitle {
    padding-top: 16px;
    color: $color-dark-2;
}