.container {
  background: linear-gradient(
    180deg,
    rgba(244, 246, 248, 0.63) 0%,
    rgba(244, 246, 248, 0) 100%
  );
}

.skipMain:focus,
.skipMain:active {
  color: #fff;
  background-color: #007bb3;
  left: auto;
  top: auto;
  width: 200px;
  height: auto;
  overflow: auto;
  padding: 13px 24px;
  border-radius: 28px;
  text-align: center;
  z-index: 101;
}
