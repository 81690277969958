.container {
  display: flex;
  justify-content: space-between;
}

.closeIcon {
  height: 24px;
  width: 24px;
  vertical-align: middle;
}

.alertLeft {
  display: flex;

  :global(.alert__container) {
    padding: 8px 0;
  }

  :global(.alert_icon) {
    margin-top: 8px;
    align-self: auto !important;
  }
}

:global(.alert__actionSlot) a {
  text-decoration: none;
  white-space: nowrap;
}
