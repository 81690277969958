@media (min-width: 768px) {
  .container {
    // The Figma has a drop shadow below the header on desktop. On mobile, the drop shadow is below the menu.
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.07));
  }
}

@media (max-width: 767px) {
  .container {
    border-bottom: 1px solid #dee0e2 !important;
  }
}
