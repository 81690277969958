@import "@hagerty/design-system/src/_variables";
@import '@hagerty/design-system/src/variables/_typeset.scss';

.root {
  background-color: $color-status-normal-lighter;
  padding: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: $color-dark-1;
  font-family: $system-mono;
}

[data-partner="state-farm"] .root {
  background-color: $color-status-critical-lighter;
}
