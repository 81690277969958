@import "@hagerty/design-system/src/_variables";

$tile-gap-medium: 24px;
$tile-gap-large: 32px;

.policyTiles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: $tile-gap-large;

  @media (max-width: 1439px) {
    gap: $tile-gap-medium;
  }
}

.tile {
  padding: 24px;
  width: 192px;
  height: 146px;

  @media (max-width: 434px) {
    padding: 16px;
  }

  @media (max-width: 1439px) {
    // wrap tiles two per row
    width: calc(50% - ($tile-gap-medium / 2));
  }

  &>p {
    margin: 0;
  }

  &>div {
    line-height: 0px;
    padding-bottom: 12px;
    align-self: flex-start;

    &>img {
      height: 32px;
      width: 32px;
    }
  }
}

.popover {
  min-width: 280px;

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $color-light-2;
    padding: 8px 0 !important;
    overflow: hidden;
    box-shadow: $shadow-fluffy;

    a {
      width: 100%;
      cursor: pointer;
      padding: 12px 24px;
      color: $color-dark-1;

      &:hover {
        background-color: $color-brand-light;
      }
    }

  }
}
