@import "@hagerty/design-system/src/_variables";

.button--black {
  background-color: $color-dark-1 !important;
  color: #ffffff !important;
  text-decoration: none !important;
  white-space: nowrap;
  width: fit-content;

  &:hover {
    background-color: $color-brand-bright !important;
  }
}

.button_secondary:disabled {
  color: $color-light-1;
}
