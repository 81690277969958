@import "@hagerty/design-system/src/_variables";

.container {
  position: relative;
  width: 100%;
  display: flex;
  color: $color-dark-1;

  img {
    width: 48px;
    height: 48px;
  }

  h3 {
    margin: 0;
    margin-top: 8px;
    padding: 0;
    font-size: 22px;
    line-height: 32px;
    font-weight: normal;
    text-align: center;
  }

  p {
    margin: 32px 0 0 0;
    padding: 0;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}
