@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.spinner {
    animation: rotating 1s linear infinite;
}