.alert {
    position: fixed;
    bottom: 24px;
    right: 24px;
    margin-left: 24px;
    z-index: 1;

    .message {
        display: flex;
        align-items: center;
    }
}