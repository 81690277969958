@import "@hagerty/design-system/src/_variables";

.dialogBodyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
}
.dialogTitle {
    color: $color-dark-1;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    margin-top: 16px;
}
.dialogMessage {
    color: $color-dark-1;
    text-align: center;
    margin-top: 16px;
}
.dialogActions {
    padding-top: 24px;
}
.dividerWrapper {
    width: 100%;
    padding: 32px 8px;
}
.dialogFooter {
    color: $color-dark-1;
    text-align: center;
}
.cancelPolicyLink {
    padding: 0px 4px;
    display: inline-block;
    font-weight: 500;
}

@media (max-width: 767px) {
    .cancelPolicyLink {
        display: block;
    }
}