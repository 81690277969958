.dt {
  line-height: 1.5;
  font-weight: 500;
  color: #090a0b;

  h4 {
    padding-bottom: 1px;
  }
  h5 {
    padding: 0 0 8px 0;
  }
}

.dd,
.dt {
  margin-top: 32px;
}

@media (max-width: 479px) {
  .login-data-table_row-body {
    text-align: left;
    margin-top: 5px;
  }
}
