.section {
  margin-bottom: 48px;

  p,
  h2,
  h3 {
    padding: 0;
  }

  p {
    padding-bottom: 16px;
  }
}
