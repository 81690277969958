.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action {
  white-space: nowrap;
  margin-left: 16px;
}
