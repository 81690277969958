@import "@hagerty/design-system/src/_variables";

$tile-gap: 24px;

.policyTiles {
  display: flex;
  justify-content: space-between;
  row-gap: $tile-gap;

  @media (max-width: 725px) {
    flex-wrap: wrap;
  }
}

.tile {
  height: 104px;
  width: 142px;
  padding-top: 24px;

  & > p {
    padding: 8px 0 0;
    margin: 0;
    line-height: 22px;
  }

  & > div {
    line-height: 0px;
    padding: 0;
    align-self: flex-start;
  }

  &:hover {
    box-shadow: $shadow-overlay !important;
  }

  @media (max-width: 725px) {
    // wrap tiles two per row
    width: calc(50% - ($tile-gap / 2));
  }
}

.popover {
  min-width: 280px;

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $color-light-2;
    padding: 8px 0 !important;
    overflow: hidden;
    box-shadow: $shadow-fluffy;
  
    a {
      width: 100%;
      cursor: pointer;
      padding: 12px 24px;
      color: $color-dark-1;
  
      &:hover {
        background-color: $color-brand-light;
      }
    }
  }
}
