@import "@hagerty/design-system/src/_variables";

.root {
  border-radius: 8px;
  background-color: white;
  border: 1px solid $color-light-3;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(9, 10, 11, 0.05);
}

a.root:hover,
button.root:hover {
  cursor: pointer;
  border: 1px solid $color-light-3;
  box-shadow: $shadow-fluffy;
}

// TODO: Should these exist?
.small {
  width: 192px;
  padding: 16px;
}

.medium {
  padding: 24px, 32px, 24px, 32px;
  max-width: 416px;
}

.full {
  width: 100%;
  padding: 32px;
}


.loadingWrapper {
  display: flex;
  justify-content: center !important;
  align-items: center;
  height: 100%;
  width: 100%;
}

