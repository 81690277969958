@import "@hagerty/design-system/src/_variables";

.rowOneWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.vehicleWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
}

.vehicleDescriptionText {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $color-dark-1;
}

.detailsRow {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  flex-flow: row;
  gap: 24px;
  color: $color-dark-2;
  white-space: wrap;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.actionWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-shrink: 0;
  column-gap: 24px;
}

.actionLink {
  color: $color-brand;
  background: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.removeButton {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

// Mobile - Move edit vehicle below vehicle name and price
@media (max-width: 719px), (min-width: 720px) and (max-width: 1024px) {
  .rowOneWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 8px;
  }

  .detailsRow {
    flex-flow: column;
    gap: 8px;
    
      span {
        width: 100%;
      }
  }

  .actionLink {
    color: $color-brand;
    background: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    align-self: flex-start;
    margin-left: 0;
  }
}
