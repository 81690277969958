.highlight {
  height: 36px;
  margin: 10px 0;
  margin-left: 10px;
}

@media (max-width: 546px) {
  .highlight {
    margin-left: 0px;
  }
}
