@import "@hagerty/design-system/src/_variables";

$separator-line-color: rgba(255, 255, 255, 0.1);

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  background-image: linear-gradient(
      77.17deg,
      #062331 28.55%,
      rgba(151, 163, 169, 0.75) 164.6%,
      #072432 164.61%
    ),
    url("./img/no-dhc-background.jpg");
  background-size: 864px, cover;
  background-position: 0 0, right 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  color: #fff;

  .logo,
  .button {
    position: absolute;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      position: relative;

      &:last-child {
        padding-bottom: 0;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 3px;
        width: 1em;
        height: 1em;

        background-image: url("./img/green-check.svg");
        background-size: contain;
      }
    }
  }

  h2 {
    padding: 0 0 24px 0;
    font-size: 34px;
    line-height: 42px;
    color: inherit;
  }
}

// Mobile - stack content vertically
@media (max-width: 1082px) {
  .container {
    background-size: 100%, cover;
    flex-direction: column;
    padding: 64px 24px 96px 24px;

    .logo {
      top: 24px;
      left: 24px;
    }

    .button {
      bottom: 24px;
      left: 24px;
    }

    ul {
      border-top: solid 1px $separator-line-color;
      width: 100%; // so horizontal bar is full width
      padding: 24px 0 0 0;

      li {
        font-size: 14px;
        line-height: 20px;
        padding: 0 0 10px 20px;
      }
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
      width: auto;
    }
  }
}

// Medium - decrease font size, height
// @media (min-width: 1090px) and (max-width: 1279px) {
@media (min-width: 1083px) and (max-width: 1355px) {
  .container {
    flex-direction: row;
    padding: 80px 32px 48px 32px;

    .logo {
      top: 32px;
      left: 32px;
    }

    .button {
      bottom: 48px;
      left: 32px;
    }

    ul {
      border-left: solid 1px $separator-line-color;
      padding-left: 32px;

      li {
        font-size: 14px;
        line-height: 20px;
        padding: 0 0 10px 20px;
      }
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
      width: 256px;
    }
  }
}

// Wide
@media (min-width: 1355px) {
  .container {
    flex-direction: row;
    padding: 80px 32px 46px 32px;

    .logo {
      top: 32px;
      left: 32px;
    }

    .button {
      bottom: 46px;
      left: 32px;
    }

    ul {
      border-left: solid 1px $separator-line-color;
      padding-left: 32px;
      font-size: 16px;
      line-height: 26px;

      li {
        font-size: 16px;
        line-height: 26px;
        padding: 0 0 8px 24px;

        &:before {
          top: 5px;
        }
      }
    }

    h2 {
      font-size: 34px;
      line-height: 42px;
      width: 304px;
    }
  }
}
