@import "@hagerty/design-system/src/_variables";

.wrapper {
    & > div:first-child {
        padding-top: 32px;
    }

    & > div:last-child {
        padding-bottom: 32px;
    }
}

.column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 8px;
    }

    .name {
        margin-right: 8px;
        color: $color-dark-1;
        font-weight: 500;
        line-height: 24px;
        min-width: 130px;
    }
    .details {
        line-height: 26px;
        color: $color-dark-2;
        display: flex;
        row-gap: 8px;
        column-gap: 16px;
        align-items: baseline;
        text-align: right;

        @media (max-width: 1024px) {
            flex-direction: column;
            text-align: left;
        }
    }
}

.link {
    color: $color-brand;
    background: none;
}

.action {
    font-size: 15px;
    font-weight: 500;
}

.tooltip {
    width: 250px;
    height: 70px;
    padding: 12px;
    font-size: 14px;
    box-shadow: $shadow-fluffy;
}

.tooltipIcon {
    margin-left: 8px;

    @media (max-width: 725px) {
        display: none;
    }
}
