@import "@hagerty/design-system/src/_variables";

.progressbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  overflow: hidden;

  .bar1,
  .bar2 {
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transition: transform 0.2s linear;
    transform-origin: left;
    background-color: $color-brand;
  }

  .bar1 {
    width: auto;
    animation: keyframesBar1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
      infinite;
  }

  .bar2 {
    width: auto;
    animation: keyframesBar2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s
      infinite;
  }
}

@keyframes keyframesBar1 {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes keyframesBar2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
