@import "@hagerty/design-system/src/_variables";

.row {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .name {
        color: $color-dark-1;
        font-weight: 500;
    }
    .value {
        color: $color-dark-2;
    }
}

@media (min-width: 720px) {
    .row {
        .value {
            text-align: right;
        }
    }
  }

@media (max-width: 719px) {
    .row {
        flex-flow: column;
        gap: 8px;
    }

    .editCoveragesButton {
        width: 100%;
    }
}

.compOnlyAlert {
    margin-top: 24px;
    margin-bottom: 32px;
}

.phoneLink {
    font-weight: 500;
    color: $color-dark-1;
    text-decoration: underline;
}
