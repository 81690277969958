@import "@hagerty/design-system/src/_variables";

.dialogHeading {
    padding-right: 16px;
}
.actionWrapper {
    margin-top: 24px;
    padding: 8px 0;
    display: flex;
    gap: 8px; 
}