@import "@hagerty/design-system/src/_variables";
@import "@hagerty/design-system/src/components/global-footer/global-footer";

// size from the existing site
.global-footer {
  font-size: 14px;
  margin-top: 64px;
}

.global-footer__flags {
  display: flex;
  align-items: center;
}

// make padding equal on both sides so the "hit area" is evenly distributed
.global-footer__flag:first-child {
  padding: 0 8px 0 0;
}
.global-footer__flag:not(:first-child) {
  padding: 0 8px;
}

.global-footer__flag img {
  width: 26px;
  height: 13px;
}

// override `font-size: rem(14)` in existing css
.global-footer__utility-links ul li {
  font-size: inherit;
}
