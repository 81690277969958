.spacing {
    margin-top: 48px;

    @media (max-width: 725px) {
        margin-top: 32px;
      }
}

.sections {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 48px;
}