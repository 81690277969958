.pane {
  display: flex;
  flex-direction: column;
  align-items: center;

  // so each pane is a consistant height and is positioned on top of the other
  // see https://observablehq.com/@rkaravia/css-trick-tabs-with-consistent-height
  width: 100%;
  margin-right: -100%;
  visibility: hidden;
}

.active {
  visibility: visible;
}
