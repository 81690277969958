@import "../../../theme/_base";

$tile-gap: 24px;

.smallTextSkeleton {
  width: 110px;
  height: 20px;

  @include skeleton;
}

.largeTextSkeleton {
  width: 340px;
  height: 24px;
  margin-bottom: 4px;

  @include skeleton;

  @media (min-width: 675px) {
    width: 606px;
    height: 26px;
    margin-bottom: 48px;
  }
}

.mobileSkeleton {
  width: 280px;
  height: 24px;
  margin-bottom: 32px;

  @include skeleton;

  @media (min-width: 675px) {
    display: none;
  }
}

.title {
  font-size: 26px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.actionsWrapper {
  display: flex;
  gap: $tile-gap;
  margin-bottom: 24px;

  & > * {
    width: calc(50% - ($tile-gap / 2));
  }

  @media (max-width: 675px) {
    flex-wrap: wrap;
  }
}

.sectionSkeleton {
  margin-bottom: 40px;
}

.buttonSkeleton {
  margin-top: 8px;
}
