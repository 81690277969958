@import "@hagerty/design-system/src/_variables";

.column {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    .name {
        color: $color-dark-1;
        font-weight: 500;
    }
    .details {
        margin-top: 8px;
        display: flex;
        align-items: flex-start;
        flex-flow: row;
        gap: 24px;
        color: $color-dark-2;
    }
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: $color-dark-2;

    svg {
        path {
            fill: $color-dark-2;
        }
    }
}

@media (max-width: 719px) {
    .footer {
        align-items: flex-start;

        svg {
            margin-top: 3px;
            flex-shrink: 0;
        }
    }
}