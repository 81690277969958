.buttonGroupItem {
  button {
    svg {
      margin: -2px 6px 0 0; // center 24px icons on buttons and give padding between icon and text
    }
  }

  &:first-child {
    padding-right: 24px; // HACK eliminate 64px of padding introduced by ".button-group__item:first-child"
  }
}
