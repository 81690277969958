@import "@hagerty/design-system/src/_variables";

button.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }

  &:disabled {
    svg {
      fill: $color-light-1;
    }
  }
}

:global(.button-close_round:disabled:hover) {
  background-color: inherit;
}
