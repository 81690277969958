.container {
  margin-bottom: -32px;
  margin-top: 32px;
  width: 100%;
  // override the padding-bottom that we use in Section.section
  // as the Bills component expects a padding-bottom of 0
  p {
    padding-bottom: 0 !important;
  }
}
