@import "@hagerty/design-system/src/_variables";

.root {
  margin-top: $space-l;

  :global(.alert__message) {
    display: flex;
    flex-direction: column;
    gap: $space-m;
    justify-content: space-between;

    @media (min-width: 480px) {
      flex-direction: row;
      gap: 0;
      align-items: center;
    }

    a {
      text-decoration: none;
    }
  }

  :global(svg) {
    display: none;
    align-self: flex-start;

    @media (min-width: 480px) {
      display: block;
    }

    @media (min-width: 768px) {
      margin-top: $space-s;
      margin-right: $space-s;
    }
  }

  .boldMessage {
    font-weight: 700;
  }

  .message {
    margin-right: 0px;
    line-height: 24px;

    @media (min-width: 480px) {
      margin-right: $space-m;
    }
  }

  .link {
    text-decoration: underline !important;
  }
}
