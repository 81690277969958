@import "@hagerty/design-system/src/_variables";

.root {
  width: 100%;

  :global(.alert__container) {
    width: 100%;
  }

  :global(.alert__message) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: $color-brand;
    }
  }
}

.boldMessage {
  font-weight: 500;
}

.message {
  font-size: 16px;
  line-height: 24px;
  margin-right: 24px;
  color: $color-dark-1;
}

.link {
  white-space: nowrap;
}
