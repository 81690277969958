@import "./_base";
@import "@hagerty/design-system/src/utilities/sizing/index";
@import "@hagerty/design-system/src/utilities/grid/index.scss";
@import '@hagerty/design-system/src/font-faces/helvetica-neue';
@import '@hagerty/design-system/src/variables/_typeset.scss';
@import "./hds";

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
}

hr {
  margin: 48px 0;
  border-top: 1px solid $color-dark-1;
  opacity: 0.1;
}

b {
  font-weight: 500;
}

// Remove arrows from input with number type

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

// https://www.bram.us/2020/03/01/prevent-content-from-being-hidden-underneath-a-fixed-header-by-using-scroll-margin-top/

* {
  scroll-margin-top: $space-xxl;
}

.site-header__logo {
  margin-left: 0;
}

// Overrides for modal to work
// TODO find out why these are necessary and remove if possible
.modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal__container {
    top: auto; // using flex center on modal above, so don't use the vertical center hack
    transform: none;
    overflow: hidden; // without this, the PDF will square off the corners on the bottom on the dialog

    .modal__body {
      max-height: calc(
        100vh - 185px
      ); // this is done by the HDS JS that we don't use
      position: relative; // keep the iframe withing the modal__body. without this, it overrides the header
    }
  }
}
