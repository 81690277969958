.container {
  display: flex;
  align-items: center;

  .logo {
    width: 78.57px;
    height: 14.93px;
    fill: currentColor;
  }

  .verticalBar {
    width: 1px;
    height: 16px;
    background-color: currentColor;
    font-size: 0;
    margin: 0 8px;
  }

  .wordMark {
    font-size: 14px;
    line-height: 15px;
    color: currentColor;
  }
}
