.subHeader {
  margin-bottom: 16px;

  & > *:not(:last-child) {
    padding-right: 32px;
  }
}

.subHeadingText {
  display: inline-block;
  margin-top: 16px;
}

@media (max-width: 582px) {
  .subHeadingText {
    margin-top: 16px;
  }
}
