@import "@hagerty/design-system/src/_variables";

.logo {
  width: 106px;
}

.subFooter {
  width: 100%;
  padding: 66px 0 66px 0;
  border-top: 1px solid $color-light-2;
  border-bottom: 1px solid $color-light-2;

  li {
    a {
      font-size: 14px;
    }
  }

  ul {
    margin-bottom: 0;
  }
}
