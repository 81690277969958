.button {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  font-family: inherit;
  vertical-align: baseline;
  touch-action: manipulation;
  color: #007bb3;
  margin-left: 1rem;
  cursor: pointer;
  background-color: transparent;
}
