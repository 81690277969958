@import "@hagerty/design-system/src/_variables";

.container {
  margin-bottom: 64px;
  margin-top: 32px;

  .titleArea {
    display: flex;
    flex-direction: column;

    h2 {
      margin: 0;
      padding: 0;
      font-stretch: expanded;
    }

    p {
      margin: 0;
      padding: 16px 0 24px 0;
    }
  }

  .road,
  .car,
  .roadNoData,
  .carNoData {
    pointer-events: none;
  }

  .button {
    display: inline-flex;
    align-items: center;
    line-height: unset;

    svg {
      margin: 0 8px 0 0;
      stroke: currentColor;
      fill: none;
    }
  }

  .footer {
    display: flex;

    span {
      color: $color-dark-1;
      font-weight: 500;
    }

    a {
      svg {
        margin-left: 8px;
      }
    }
  }
}

// Mobile - stack content vertically
@media (max-width: 719px), (min-width: 768px) and (max-width: 1024px) {
  .container {
    position: relative;

    .titleArea {
      p {
        font-size: 16px;
        line-height: 26px;
      }

      h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .road,
    .car,
    .roadNoData,
    .carNoData {
      display: none;
    }

    .line {
      position: absolute;
      left: 32px;
      right: 32px;
      height: 0;
      bottom: 106px;
      border-bottom: 1px solid $color-light-2;
    }

    .footer {
      padding-top: 56px;
      width: 244px;
      font-size: 16px;
      flex-direction: column;

      a {
        line-height: 26px;
      }
    }
  }
}

// Medium - decrease font size, height
// @media (min-width: 1090px) and (max-width: 1279px) {
@media (min-width: 720px) and (max-width: 767px),
  (min-width: 1025px) and (max-width: 1149px) {
  .container {
    position: relative;

    .titleArea {
      width: 384px;

      p {
        font-size: 16px;
        line-height: 26px;
      }

      h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .road,
    .car {
      position: absolute;
      width: 285px;
      height: 129px;
      right: 32px;
      bottom: 84px;
    }

    .roadNoData,
    .carNoData {
      position: absolute;
      width: 285px;
      height: 129px;
      right: 32px;
      bottom: 43px;
    }

    .road,
    .roadNoData {
      width: calc(100% - 64px);
      left: 32px;
    }

    .line {
      display: none;
    }

    .footer {
      padding-top: 85px;
      width: 100%;
      font-size: 16px;
      justify-content: flex-end;

      a {
        margin-left: 10px;
      }
    }
  }
}

// Wide
@media (min-width: 1150px) {
  .container {
    position: relative;

    .titleArea {
      width: 416px;

      p {
        font-size: 18px;
        line-height: 28px;
      }

      h2 {
        font-size: 30px;
        line-height: 38px;
      }
    }

    .road,
    .car {
      position: absolute;
      width: 322px;
      height: 146px;
      right: 32px;
      bottom: 82px;
    }

    .roadNoData,
    .carNoData {
      position: absolute;
      width: 322px;
      height: 146px;
      right: 32px;
      bottom: 43px;
    }

    .road,
    .roadNoData {
      width: calc(100% - 64px);
      left: 32px;
    }

    .line {
      display: none;
    }

    .footer {
      padding-top: 85px;
      width: 100%;
      font-size: 16px;
      justify-content: flex-end;

      a {
        margin-left: 10px;
      }
    }
  }
}
