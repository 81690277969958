$tile-gap: 24px;

.hdcTiles {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 32px;

  @media (max-width: 1439px) {
    flex-wrap: wrap;
    gap: $tile-gap;
  }
}

.tile {
  padding: 24px;

  @media (max-width: 434px) {
    padding: 16px;
  }

  @media (max-width: 1439px) {
    // wrap tiles two per row
    width: calc(50% - ($tile-gap / 2));
  }

  & > p {
    margin: 0;
  }

  & > div {
    line-height: 0px;
    padding-bottom: 12px;
    align-self: flex-start;
  }
}
